.filter-collapsible {
  padding: 1rem 1.5rem;
  margin-bottom: 1.5rem;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
}

.filterattributes {
  width: calc(100% + 1.5rem);
  margin-left: -0.75rem;
}

.filterattributes td,
.filterattributes th {
  width: 25%;
  text-align: left;
  vertical-align: top;
  padding: 0 0.75rem;
}

.show_more_options {
  background: transparent;
  border: none;
  display: block;
  position: relative;
  background-image: linear-gradient(to bottom, transparent 0%, #f5f5f5 100%);
  margin-top: -7rem;
  height: 7rem;
  width: 100%;
  content: '';
  cursor: pointer;
}

.show_more_options span {
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: 2px;
  left: 50%;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-top: 3px solid #333;
  border-right: 3px solid #333;
  transform: rotate(135deg);
  cursor: pointer;
}
.show_more_options.is-open {
  margin-top: 0rem;
  height: 3rem;
}
.show_more_options.is-open span {
  transform: rotate(315deg);
  bottom: 1rem;
}

.attributes_container {
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.attributes_container.hide-rest {
  max-height: 24rem;
  overflow: hidden;
  transition: all 0.3s ease;
}

.attributes_container.hide-rest.is-open {
  max-height: 115rem;
}

@media (min-width: 960px) {
  .attributes_container {
    grid-template-columns: repeat(3, 1fr);
  }
  .attributes_container.hide-rest {
    max-height: 24rem;
  }
}
@media (min-width: 1200px) {
  .attributes_container {
    grid-template-columns: repeat(4, 1fr);
  }
  .attributes_container.hide-rest {
    max-height: 22rem;
  }
}

.render_option_heading {
  hyphens: auto;
  word-break: break-word;
}
