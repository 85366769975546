.container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.attributeContainer {
  display: inline-flex;
  align-items: center;
  background-color: #0d2e54;
  font-size: 14px;
  border-radius: 8px;
  color: white;
  padding: 5px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.filterValue {
  margin-left: 5px;
  background-color: #1a5fae;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.filterValue-exit{
  margin-left: 0.5rem;
}

.filterValue-exit:hover {
  color: #ea4520;
  cursor: pointer;
}
