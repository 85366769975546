.custom-hit-header-container {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.dataLink {
  font-style: 'italic';
  font-weight: 700;
  font-size: 18px;
  text-decoration: none;
  vertical-align: middle;
  line-height: 1.5;
  color: #eb4800;
}
.dataLink svg {
  width: 1.25rem;
  margin-left: 4px;
  fill: #eb4800;
  transform: translateY(2px);

}
.dataLink:hover {
  color: #f15c1c;
}
.dataLink:hover svg {
  fill: #f15c1c;
}
.imgContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 35px;
}

.productImg {
  height: 100%;
  width: auto;
  max-height: 75px;
  border-radius: 5px;
}

@media (min-width: 960px) {
  .productImg {
    max-height: 100px;
  }
}
