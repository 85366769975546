body,
h1 {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  width: 100vw;
  overflow-x: hidden;
}

.filter-collapsible {
  padding: 1rem 1.5rem;
  margin-bottom: 1.5rem;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
}

.filterattributes {
  width: calc(100% + 1.5rem);
  margin-left: -0.75rem;
}

.filterattributes td,
.filterattributes th {
  width: 25%;
  text-align: left;
  vertical-align: top;
  padding: 0 0.75rem;
}

.show_more_options {
  background: transparent;
  border: none;
  display: block;
  position: relative;
  background-image: linear-gradient(to bottom, transparent 0%, #f5f5f5 100%);
  margin-top: -7rem;
  height: 7rem;
  width: 100%;
  content: '';
  cursor: pointer;
}

.show_more_options span {
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: 2px;
  left: 50%;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-top: 3px solid #333;
  border-right: 3px solid #333;
  transform: rotate(135deg);
  cursor: pointer;
}
.show_more_options.is-open {
  margin-top: 0rem;
  height: 3rem;
}
.show_more_options.is-open span {
  transform: rotate(315deg);
  bottom: 1rem;
}

.attributes_container {
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.attributes_container.hide-rest {
  max-height: 24rem;
  overflow: hidden;
  transition: all 0.3s ease;
}

.attributes_container.hide-rest.is-open {
  max-height: 115rem;
}

@media (min-width: 960px) {
  .attributes_container {
    grid-template-columns: repeat(3, 1fr);
  }
  .attributes_container.hide-rest {
    max-height: 24rem;
  }
}
@media (min-width: 1200px) {
  .attributes_container {
    grid-template-columns: repeat(4, 1fr);
  }
  .attributes_container.hide-rest {
    max-height: 22rem;
  }
}

.render_option_heading {
  -webkit-hyphens: auto;
          hyphens: auto;
  word-break: break-word;
}

.custom-hit-header-container {
  display: flex;
  justify-content: space-between;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.dataLink {
  font-style: 'italic';
  font-weight: 700;
  font-size: 18px;
  text-decoration: none;
  vertical-align: middle;
  line-height: 1.5;
  color: #eb4800;
}
.dataLink svg {
  width: 1.25rem;
  margin-left: 4px;
  fill: #eb4800;
  transform: translateY(2px);

}
.dataLink:hover {
  color: #f15c1c;
}
.dataLink:hover svg {
  fill: #f15c1c;
}
.imgContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 35px;
}

.productImg {
  height: 100%;
  width: auto;
  max-height: 75px;
  border-radius: 5px;
}

@media (min-width: 960px) {
  .productImg {
    max-height: 100px;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.attributeContainer {
  display: inline-flex;
  align-items: center;
  background-color: #0d2e54;
  font-size: 14px;
  border-radius: 8px;
  color: white;
  padding: 5px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.filterValue {
  margin-left: 5px;
  background-color: #1a5fae;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.filterValue-exit{
  margin-left: 0.5rem;
}

.filterValue-exit:hover {
  color: #ea4520;
  cursor: pointer;
}

em {
  background: #032854;
  font-style: normal;
}

a:hover {
  color: #284378;
}

a {
  color: #284378;
}

h5 {
  margin-bottom: 0.5rem;
}

.header {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 1rem 1rem;
  background-color: #032854;
  color: #fff;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.header-subtitle {
  font-size: 1.2rem;
  padding: 0 5px;
}

.header img {
  width: auto;
  height: 3rem;
}

.container {
  max-width: 95%;
  margin: 0 auto;
  padding: 0.5rem;
}

.description {
  padding: 1rem;
  margin-bottom: 1.5rem;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
}
.description > p {
  margin: auto;
}
.description > ol {
  list-style: disc;
  padding: 0.25rem;
  margin: 0 1rem;
}

.search-panel-header {
  display: flex;
}
.switch-container {
  margin-right: 1rem;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 700px) {
  .switch-container {
    display: none;
  }
  .search-panel__results.row {
    flex-direction: column !important;
  }
  .search-panel__filters.row,
  .results.row {
    width: 100% !important;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.6rem;
  margin-right: 0.3rem;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #0d2e54;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0d2e54;
}

input:checked + .slider:before {
  transform: translateX(22px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.search-panel {
  display: block;
  width: 100%;
}

.search-panel__results {
  display: flex;
  flex-wrap: wrap;
}
.search-panel__results.column {
  flex-direction: column;
}
.search-panel__results.row {
  flex-direction: row;
}
.search-panel__filters {
  margin-top: 2rem;
  margin-right: 2rem;
  transition: all 0.15s ease;
}
.search-panel__filters.column,
.results.column {
  width: 100%;
}
.search-panel__filters.row {
  width: calc(40% - 2rem);
}
.results.row {
  width: 60%;
}

.searchbox {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pagination {
  margin: 2rem auto;
  text-align: center;
}

.ais-InfiniteHits-item {
  width: 100%;
  overflow: hidden;
}

.custom-hit-header {
  margin-bottom: 4px;
}

.custom-hit-header-title * {
  font-size: 0.9em;
  font-weight: 700;
  margin-right: 5px;
}

.custom-hit-header-subtitle * {
  font-size: 0.8em;
  font-weight: 500;
  margin-right: 5px;
}

.custom-hit-category {
  width: 100%;
}

.custom-hit-category * {
  font-size: 0.7em;
  margin-right: 4px;
  font-style: italic;
  font-weight: 700;
}

.custom-hit-body {
  display: grid;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #c4c8d8;
  grid-template-columns: repeat(2, 1fr);
}

@media (min-width: 960px) {
  .custom-hit-body {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1200px) {
  .custom-hit-body {
    grid-template-columns: repeat(4, 1fr);
  }
  .custom-hit-header-title * {
    font-size: 1.1em;
  }

  .custom-hit-header-subtitle * {
    font-size: 1em;
  }

  .custom-hit-category * {
    font-size: 0.8em;
  }
}

.custom-hit-body-attribute {
  margin-bottom: 8px;
}

.custom-hit-body-attribute > * {
  display: block;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.custom-hit-body-attribute-name {
  font-weight: 700;
  font-size: 0.8em;
}

.custom-hit-body-attribute-value {
  font-weight: 500;
  font-size: 0.9em;
}

.hit-collapsible {
  padding: 5px;
}

button[type='submit']:not(.ais-SearchBox-submit),
.ais-InfiniteHits-loadMore,
.ais-Pagination-link--selected,
.ais-Pagination-item--selected .ais-Pagination-link,
.ais-ClearRefinements-button {
  background-color: #032854;
  color: #fff;
}

button[type='submit']:not(.ais-SearchBox-submit):hover,
.ais-InfiniteHits-loadMore:hover,
.ais-Pagination-item--selected .ais-Pagination-link:hover,
.ais-RangeInput-submit:hover,
.ais-ClearRefinements-button:hover {
  background-color: #054080;
  color: #fff;
}

.ais-RangeInput-input {
  width: 4rem;
}

.ais-Pagination-link,
.ais-Pagination-link:hover {
  color: #032854;
}

button[disabled],
button[disabled]:hover {
  background-color: #808080;
}
.ais-RefinementList.overlay {
  display: block;
  position: relative;
}
.ais-RefinementList.overlay::after {
  display: block;
  position: relative;
  background-image: linear-gradient(to bottom, transparent 0%, #f5f5f5 100%);
  margin-top: -1.8rem;
  height: 2rem;
  width: 100%;
  content: '';
  cursor: pointer;
}
.ais-RefinementList.overlay.is-open::after {
  margin-top: 0px;
}

.ais-RefinementList.overlay > .ais-RefinementList-list {
  max-height: 100px;
  min-height: 90px;
  overflow: hidden;
  transition: max-height 0.1s ease-out;
}
.ais-RefinementList.overlay.is-open > .ais-RefinementList-list {
  max-height: 70rem;
  transition: max-height 0.15s ease-out;
}
.ais-RefinementList.overlay > .ais-RefinementList-list::after {
  content: '';
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-top: 3px solid #333;
  border-right: 3px solid #333;
  transform: rotate(135deg);
  cursor: pointer;
  transition: transform 0.2s ease-in;
}
.ais-RefinementList.overlay.is-open > .ais-RefinementList-list::after {
  transform: rotate(315deg);
  bottom: 1rem;
}
.ais-RefinementList-item {
  float: left;
  word-break: break-all;
}

.ais-RefinementList-label {
  display: flex;
  align-items: flex-start;
  line-height: 1;
}

.ais-RefinementList-labelText {
  margin-right: 0.25rem;
  word-break: break-word;
  line-height: 1.25;
}

.ais-RefinementList-item--empty {
  opacity: 0.5;
}

#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(3, 40, 84, 0.3);
  border-radius: 50%;
  border-top-color: #032854;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.Collapsible__trigger {
  text-transform: capitalize;
  cursor: pointer;
  margin: 0;
  position: relative;
  font-size: 1.5rem;
  line-height: 1;
}

.Collapsible__trigger::after {
  content: '';
  position: absolute;
  right: 0.5rem;
  top: 0.25rem;
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  border-top: 0.3rem solid #333;
  border-right: 0.3rem solid #333;
  transform: rotate(135deg);
  transition: transform 0.3s ease-in;
}

.Collapsible__trigger.is-open::after {
  transform: rotate(315deg);
}

.Collapsible__contentInner {
  padding: 0.5rem 0 0;
}

.copyright {
  margin-left: auto;
}
.copyright p {
  font-size: 1rem;
  font-weight: 100;
}

